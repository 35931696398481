\<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">

            <div class="center-text">
                <p></p>
                <span>{{name}}</span>
            </div>
            
            <div class="state-wrap">
                <div class="state" v-for="(item,index) in guojiaList" :key="index">
                    <div class="state-list">
                        <div class="state-item-text">{{item.name}}</div>
                          <!-- @click="goTo('/pingtai_list',{p_id:item1.p_id,id:item1.id})" -->
                        <div class="state-item" v-for="(item1,index1) in item.cs.slice(0,6)" :key="index1"   @click="goTo('/msg_list',{id:item1.id,name:item1.name})">
                            <img :src="item1.banner_url" alt="">
                            <p>{{item1.name}}</p>
                        </div>                      
                    </div>
                    <div class="more"  @click="goTo('/msg_state',{key:index})">
                        <p>更多</p>                         
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="kuajing-bj">
            <img :src="gg[0]?gg[0].pic_url:require('../../../assets/images/xinxiku.png')" alt="">
            <!-- <div class="kuajing-p">
                <div class="kuajing-p1">服务产品，突破想象</div>
                <div class="kuajing-p2">专注于服务出口企业，打造N+1网络营销模式，取得真实询盘才是解决之道！</div>
                <div class="kuajing-bt">view more</div>
            </div> -->
        </div>
        <div class="center-text hzytext w-1300">
            <p></p>
            <span>行业大类</span>
        </div>
        <div class="shop-wrap w-1300">
            <div class="shop-left">
                <div class="pinpai-list-text">信息库行业分类<p></p></div>
                <div class="pinpai-item-wrap">                        
                    <div class="pinpai-item" v-for="(item,index) in liebiaoList" :key="index" @click="toTab(index)">
                        <p :class="{active:key==index}">{{item.name}}</p>
                        <img src="../../../assets/images/right.png" alt="">
                    </div>
                </div>
            </div>
            <div class="shop-right">
                <div class="shop-search-wrap">
                    <div class="shop-search">
                        <input type="text"  v-model="search" placeholder="行业/国家">
                        <p @click="searchClick"><i class="el-icon-search"></i></p>
                    </div>
                    <div class="shop-search-p" @click="goTo('msg_list')">查看全部客户信息<i class="el-icon-arrow-right"></i></div>
                </div>
                <div class="shop-table">
                    <el-table :data="tableList" stripe  style="width: 100%" >
                        <el-table-column  prop="pla_name" label="行业分类" min-width="30%">
                        </el-table-column>
                        <el-table-column  prop="coun_name" label="国家" min-width="20%">
                        </el-table-column>
                        <el-table-column  prop="name" label="公司名称" min-width="30%">
                        </el-table-column>
                        <el-table-column prop="address" label="公司地址" min-width="40%">
                        </el-table-column>
                        <el-table-column prop="link" label="官网" min-width="30%">
                        </el-table-column>
                        <el-table-column prop="full" label="联系人" min-width="20%">
                        </el-table-column>
                        <!-- <el-table-column prop="phone" label="电话" min-width="30%">
                        </el-table-column> -->
                    </el-table>
                </div>
            </div>

        </div>



    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import {getcountrys,getplates,getplatexts,getplateAds} from '../../../api/index'
    export default {
        data() {
            return {
                name:'',
                guojiaList:[],
                liebiaoList:[],
                key:0,
                tableList:[],
                gg:[],
                search:''
            }
        },
        components:{
            banner
        },
        created() {
            this.getName()
            this.getcountrys()  
            this.getplates()  
            this.getplateAds()        
        },
        methods: {
            //获取分类名字
            getName(){
                getplates({
                    p_id:1
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        // this.fuwuList=res.data
                        for (let i = 0; i < res.data.length; i++) {
                             if (this.$route.query.hzy_id==res.data[i].id) {
                                 this.name=res.data[i].name
                                 return;
                             }
                        }
                    }
                })
            },
            // 广告
            getplateAds(){
                getplateAds({
                    plate_id:10,
                    seat:0
                }).then((res)=>{
                    console.log(res)
                    if(res.code==200){
                        this.gg=res.data
                    }
                })
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            toTab(key){
                this.key=key;
                this.search='';
                this.getplates()
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data
                    }
                })
            },
            //获取列表
            getplates(){
                getplates({
                    p_id:10
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data
                        this.getplatexts(res.data[this.key].id)
                    }
                })
            },
            //获取表格
            getplatexts(plate_id){
                // console.log(plate_id)
                getplatexts({
                    plate_id:plate_id,
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.tableList=res.data.data
                        
                    }

                })
            },

            //搜索
            searchClick(){
                if (this.search=='') {
                    return false
                }
                // console.log(plate_id)
                getplatexts({
                    plate_id:10,
                    search:this.search,
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.tableList=res.data.data
                        
                    }

                })
            }
            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{overflow: hidden;}
    .hzytext{margin: auto;margin-top: 45px;}
    .tuijian-wrap{
        margin: auto;
        .state-wrap{
            margin-top: 10px;overflow: hidden;padding:0 20px;
            .state{
                 margin:35px 0;display: flex;align-items: center;justify-content: space-between;
                .state-list{
                    display: flex;align-items: center;flex: 1;
                    .state-item-text{color: #000;font-size: 16px;font-weight: bold;width: 130px;}
                    .state-item{
                        display: flex;align-items: center;font-size:16px;color:#000;cursor: pointer;
                        img{width: 28px;margin-right: 10px;}
                        p{width: 120px;}
                        &:hover{color: #FF7048;text-decoration:underline;}
                    }
                }
                .more{
                    display: flex;align-items: center;color: #FF7048;flex-shrink: 0;cursor: pointer;font-size: 16px;
                    p{margin: 0 5px 1px 0;}
                }
            }

        }
        
    }

    .kuajing-bj{
        width: 100%;position: relative;margin-top:40px;
        img{width: 100%;height: auto;}
        .kuajing-p{
            width: 400px;position: absolute;top:50%;left:33%;transform: translate(-50%,-50%);
            .kuajing-p1{color: #333333;font-size: 34px;margin-bottom: 15px;}
            .kuajing-p2{color: #222;font-size: 16px;font-weight: 300;line-height: 24px;}
            .kuajing-bt{
                width: 141px;height: 36px;background: #0071DB;
                border-radius: 20px;line-height: 36px;text-align: center;
                color: #fff;font-size: 17px;font-weight: 300;
                margin-top: 30px;cursor: pointer;
            }
        }
    }

    .shop-wrap{
        margin: auto;
        margin-top: 45px;
        margin-bottom: 140px;
        display: flex;
        .shop-left{
            width: 20%;flex-shrink: 0;
            .pinpai-list-text{position: relative;
                height: 43px;line-height: 43px; background: #0071DB;padding-left: 45px;font-size: 18px;font-weight: bold;color: #fff;
                p{width: 11px;height: 11px;background: #fff;border-radius: 50%;position: absolute;top: 16px;left: 22px;}    
            }
            .pinpai-item-wrap{width: 100%;background: #EEEFF0;padding-bottom: 28px;}
            .pinpai-item{padding: 28px 37px 0 45px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;
                p{color: #333333;font-size: 18px;}
                img{width: 8px;}
                &:hover{
                    p{color: #0071DB;}
                }
            }
            .active{color: #0071DB !important;}
        }
        .shop-right{
            // flex: 1;
            width: 80%;
            padding-left: 20px;
            box-sizing: border-box;
            .shop-search-wrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;
                .shop-search{
                    height: 36px;
                    display: flex;
                    align-items: center;
                    border: 1px solid #CECECE;
                    padding-left: 20px;                    
                    border-radius:20px;
                    input{
                        background:none;outline:none;border:0px;
                        font-size: 16px;
                    }
                    p{font-size: 20px;color: #666;cursor: pointer;padding-right:20px;padding-left: 10px;}
                }
                .shop-search-p{
                    font-size: 16px;color: #FF7048;cursor: pointer;
                    i{padding-left: 10px;}
                }
            }
            .shop-table{width: 100%;}
            /deep/ .el-table th{background: #DAE6F8;color: #000;font-size: 18px;font-weight: 500;}
            /deep/ .el-table td{font-size: 16px;color: #000;}
            /deep/.el-table--striped .el-table__body tr.el-table__row--striped td{background: #F2F2F2;}
        }
    }

</style>